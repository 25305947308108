import { $authHost } from "./index";

export default class StatsService {
    static async getBalance() {
        return $authHost.get(`/api/v1/stats/balance`)
    }

    static async getTotals() {
        return $authHost.get(`/api/v1/stats/totals`)
    }

    static async getWalletsTotals() {
        return $authHost.get(`/api/v1/stats/wallets/totals`)
    }

    static async getMonthsTotals(tx_type) {
        return $authHost.get(`/api/v1/stats/totals/months?tx_type=${tx_type}`)
    }
}