import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import classes from './AppNavbar.module.scss';
import { Context } from '../../../';
import SessionsService from '../../../http/SessionsService';
import { DASHBOARD_ROUTE, MAIN_ROUTE, SETTINGS_ROUTE } from '../../../utils/consts';
import { ReactComponent as HomeSVG } from '../../../assets/icons/home.svg'
import { ReactComponent as PremiumSVG } from '../../../assets/icons/premium.svg'
import { ReactComponent as PlusSVG } from '../../../assets/icons/plus.svg'
import settingsPNG from '../../../assets/icons/settings.png'
import logoutPNG from '../../../assets/icons/logout.png'
import { RemoveAccessToken } from '../../../utils/token';

import NavbarButtonWhite from '../../Common/NavbarButton/NavbarButtonWhite';
import FilledTransparentNarrowButton from '../../Common/Button/FilledTransparentNarrowButton';
import ModalTx from '../Modal/ModalTx';

const AppNavbar = ({ isActive, setIsActive }) => {
    const { user } = useContext(Context);
    const [dropdownWindowActive, setDropdownWindowActive] = useState(false);
    const [isModalTxOpen, setIsModalTxOpen] = useState(false);
    const navigate = useNavigate();

    const toggleDropdownWindow = () => {
        setDropdownWindowActive(!dropdownWindowActive);
    }

    const handleLogout = async () => {
        SessionsService.delete().then(() => {
            RemoveAccessToken()
            user.setUser({})
            user.setIsAuth(false)
            navigate(0);
        }).catch(e => {
            console.log("logout error", e)
        })
    }

    const dropdownWindowOptions = [
        { title: 'Settings', image: settingsPNG, url: SETTINGS_ROUTE, onClick: null, show: true },
        { title: 'Logout', image: logoutPNG, url: MAIN_ROUTE, onClick: handleLogout, show: true },
    ]

    return (
        <>
            {isModalTxOpen && <ModalTx setIsOpen={setIsModalTxOpen} />}

            <nav className={classes.navbar}>

                <div className={classes.left}>

                    <NavbarButtonWhite isActive={isActive} setIsActive={setIsActive} />

                    <Link to={DASHBOARD_ROUTE} className={classes.home}>
                        <HomeSVG style={{ width: '24px', height: '24px', color: '#fff', path: '24px' }} />
                    </Link>
                </div>

                <div className={classes.right}>
                    {!user.user.is_premium
                        ?
                        <FilledTransparentNarrowButton>
                            <PremiumSVG />Upgrade to Pro
                        </FilledTransparentNarrowButton>
                        : null
                    }

                    <div className={classes.addButton} onClick={() => setIsModalTxOpen(true)}>
                        <PlusSVG style={{ color: '#fff' }} />
                    </div>

                    <div className={classes.userMenu} onClick={toggleDropdownWindow}>
                        <div className={classes.textedAvatar}>{user.user.email[0].toUpperCase()}</div>

                        <div className={`${classes.dropdownWindow} ${dropdownWindowActive ? classes.dropdownWindowActive : ''}`}>
                            <div className={classes.avatarName}>
                                <div className={classes.textedAvatar}>{user.user?.email[0].toUpperCase()}</div>
                                <div className={classes.nicknameAndEmail}>
                                    <div className={classes.nickname}>{user.user.email?.split('@')[0]}</div>
                                    <div className={classes.email}>{user.user?.email}</div>
                                </div>

                            </div>

                            {dropdownWindowOptions.map((item, i) => {
                                return (
                                    <Link to={item.url} key={i}>
                                        {item.show && <div className={classes.item} onClick={item.onClick}>
                                            <img src={item.image} alt="dropdown-item-logo" /><span>{item.title}</span>
                                        </div>}
                                    </Link>
                                )
                            })}
                        </div>
                    </div>
                </div >
            </nav >
        </>
    );
};

export default AppNavbar;