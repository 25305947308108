import React from 'react';

import classes from './Pagination.module.scss';

const Pagination = ({ totalPages, activePage, paginate }) => {
    const isWithinRange = (number) =>
        number === 1 ||
        number === totalPages ||
        (number >= activePage - 1 && number <= activePage + 1)

    const pageNumbers = []
    for (let i = 1; i <= totalPages; i++) {
        if (isWithinRange(i)) {
            pageNumbers.push(i)
        } else if (
            (i === 1 && !isWithinRange(i)) ||
            (i === totalPages - 2 && !isWithinRange(i))
        ) {
            pageNumbers.push('...')
        }
    }

    return (
        <div className={classes.container}>
            <div className={classes.pagination}>
                {pageNumbers.map((number) => {
                    return (
                        <div
                            key={number}
                            onClick={() => { if (number !== '...') paginate(number) }}
                            className={`${number === '...' ? classes.dots : ''}  ${activePage === number ? classes.active : ''}`}
                        >
                            {number}
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default Pagination;