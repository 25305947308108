import React, { useState } from 'react';

import classes from './PageLayout.module.scss';

import AppNavbar from './AppNavbar/AppNavbar';
import LeftSideMenu from './LeftSideMenu/LeftSideMenu';

const AppPageLayout = ({ header, children }) => {
    const [isNavbarActive, setIsNavbarActive] = useState(false)

    return (
        <div className={classes.appPageContainer}>
            <AppNavbar
                isActive={isNavbarActive}
                setIsActive={setIsNavbarActive}
            />

            <div className={classes.subContainer}>
                <div className={`${classes.left} ${isNavbarActive ? classes.leftActive : ''}`}>
                    <LeftSideMenu />
                </div>

                <div className={classes.right}>
                    <div className={classes.content}>
                        {header}
                        {children}
                    </div>
                </div>
            </div>

            <div
                className={`${isNavbarActive ? classes.darkBackground : ''}`}
                onClick={() => setIsNavbarActive(false)}
            ></div>
        </div>
    );
};

export default AppPageLayout;