import React from 'react';

import classes from './ForgotPasswordForm.module.scss';

import FilledPurpleFullWidthButton from '../../../Common/Button/FilledPurpleFullWidthButton';
import Input from '../../../Common/Input/Input';


const ForgotPasswordForm = ({ clickHandler, value, setValue }) => {
    return (
        <div className={classes.container}>
            <form onSubmit={clickHandler}>
                <Input
                    label='Email'
                    onChange={e => setValue(e.target.value)}
                    value={value}
                    type='text'
                    placeholder="Enter your email..."
                />

                <FilledPurpleFullWidthButton>Reset my password</FilledPurpleFullWidthButton>
            </form>
        </div>
    );
};

export default ForgotPasswordForm;