import { AUTH_PASSWORD_ROUTE, CATEGORIES_ROUTE, DASHBOARD_ROUTE, AUTH_PASSWORD_RESET_ROUTE, LOGIN_ROUTE, REGISTRATION_ROUTE, SETTINGS_ROUTE, TRANSACTIONS_ROUTE, WALLETS_ROUTE, MAIN_ROUTE } from "./utils/consts"

import Dashboard from "./pages/private/Dashboard"
import Wallets from "./pages/private/Wallets"
import Transactions from "./pages/private/Transactions"
import Categories from "./pages/private/Categories"
import Settings from "./pages/private/Settings"

import Auth from "./pages/public/Auth"
import Main from "./pages/public/Main"
import RestorePassword from "./pages/public/ForgotPassword"
import ResetPassword from "./pages/public/ResetPassword"

export const privateRoutes = [
    { path: DASHBOARD_ROUTE, Component: Dashboard },
    { path: WALLETS_ROUTE, Component: Wallets },
    { path: TRANSACTIONS_ROUTE, Component: Transactions },
    { path: CATEGORIES_ROUTE, Component: Categories },
    { path: SETTINGS_ROUTE, Component: Settings },
    { path: AUTH_PASSWORD_RESET_ROUTE, Component: ResetPassword },
]

export const publicRoutes = [
    { path: MAIN_ROUTE, Component: Main },
    { path: LOGIN_ROUTE, Component: Auth },
    { path: REGISTRATION_ROUTE, Component: Auth },
    { path: AUTH_PASSWORD_ROUTE, Component: RestorePassword },
    { path: AUTH_PASSWORD_RESET_ROUTE, Component: ResetPassword },
]
