import { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import classes from './Dashboard.module.scss'
import { Context } from '../..';
import TransactionsService from '../../http/TransactionsService';
import StatsService from '../../http/StatsService';
import totalBalanceLogo from '../../assets/icons/dashboard/total_balance.png'
import incomeLogo from '../../assets/icons/dashboard/income.png'
import expenseLogo from '../../assets/icons/dashboard/expense.png'
import { EXPENSE_TX_TYPE, INCOME_TX_TYPE, WALLETS_ROUTE } from '../../utils/consts';
import { formatMoney } from '../../utils/money';
import { calcExpIncPercChange, calcTotalBalancePercChange } from '../../utils/profitloss';

import AppPageLayout from '../../components/PageLayout/AppPageLayout';
import ModalTx from '../../components/PageLayout/Modal/ModalTx';
import Header from '../../components/PageLayout/Header/Header';
import Transactions from '../../components/PageLayout/Transactions/Transactions';
import Bar from '../../components/Common/Bar/Bar';
import FilledPurpleSmallButton from '../../components/Common/Button/FilledPurpleSmallButton';

const Dashboard = () => {
    const { user } = useContext(Context);

    const [isModalTxOpen, setIsModalTxOpen] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [txs, setTxs] = useState([])
    const [totalBalance, setTotalBalance] = useState('No data')
    const [totalBalanceRaw, setTotalBalanceRaw] = useState()
    const [expense, setExpense] = useState('No data')
    const [income, setIncome] = useState('No data')
    const [chartTitle, setChartTitle] = useState('')
    const [chartExpenseData, setChartExpenseData] = useState([])
    const [chartIncomeData, setChartIncomeData] = useState([])

    useEffect(() => {
        TransactionsService.getAll(1, 5, true, '', '', '', '', '').then(resp => {
            setTxs(resp.data.txs)
        })

        StatsService.getBalance().then(resp => {
            setTotalBalance(formatMoney(resp.data.balance.currency, resp.data.balance.amount))
            setTotalBalanceRaw(resp.data.balance.amount)
        })

        StatsService.getTotals().then(resp => {
            setExpense(formatMoney(resp.data.totals.currency, resp.data.totals.expense))
            setIncome(formatMoney(resp.data.totals.currency, resp.data.totals.income))
        })

        StatsService.getMonthsTotals(EXPENSE_TX_TYPE).then(resp => {
            setChartTitle(`Expense/Income Totals (${resp.data.months_totals.currency})`)
            setChartExpenseData(resp.data.months_totals.totals)
        })

        StatsService.getMonthsTotals(INCOME_TX_TYPE).then(resp => {
            setChartIncomeData(resp.data.months_totals.totals)
        })

        setIsUpdated(false)
    }, [isModalTxOpen, isUpdated])

    const totalBalanceChange = calcTotalBalancePercChange(totalBalanceRaw, chartIncomeData, chartExpenseData)
    const incomeChange = calcExpIncPercChange(chartIncomeData)
    const expenseChange = calcExpIncPercChange(chartExpenseData)

    const panels = [
        {
            title: 'BALANCE',
            amount: totalBalance,
            logo: totalBalanceLogo,
            link: WALLETS_ROUTE,
            isProfit: totalBalanceChange.isProfit,
            msg: `${totalBalanceChange.percentage} than last month`,
            display: totalBalanceChange.display,
        },
        {
            title: 'INCOME',
            amount: income,
            logo: incomeLogo,
            link: null,
            isProfit: incomeChange.isProfit,
            msg: `${incomeChange.percentage} than last month`,
            display: incomeChange.display,
        },
        {
            title: 'EXPENSES',
            amount: expense,
            logo: expenseLogo,
            link: null,
            isProfit: expenseChange.isProfit,
            msg: `${expenseChange.percentage} than last ${user.user.period}`,
            display: expenseChange.display,
        },
    ]


    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Expense',
                data: chartExpenseData,
                backgroundColor: 'rgba(255, 99, 132, 0.8)',
            },
            {
                label: 'Income',
                data: chartIncomeData,
                backgroundColor: 'rgba(116, 233, 200, 0.8)',
            },
        ],
    }

    return (
        <AppPageLayout
            header={<Header
                title='DASHBOARD'
                button={<FilledPurpleSmallButton onClick={() => setIsModalTxOpen(true)}>Add transaction</FilledPurpleSmallButton>}
            />}
        >
            <div className={classes.container}>
                {isModalTxOpen && <ModalTx setIsOpen={setIsModalTxOpen} setIsUpdated={setIsUpdated} />}

                <div className={classes.balanceInfo}>
                    {panels.map((panel, i) => {
                        return (
                            <Link to={panel.link} key={i}>
                                <div className={classes.totalDisplay}>
                                    <div className={classes.first}>
                                        <div className={classes.title}>{panel.title}</div>
                                        <div className={classes.logo}><img src={panel.logo} alt='balance' /></div>
                                    </div>
                                    <div className={classes.second}>
                                        <div className={classes.amount}>{panel.amount}</div>
                                    </div>
                                    {panel.display
                                        ? <div className={classes.third}>
                                            {panel.isProfit
                                                ? <div className={classes.profitLabel}>↑</div>
                                                : <div className={classes.lossLabel}>↓</div>
                                            }
                                            <div className={classes.growthInfo}>{panel.msg}</div>
                                        </div>
                                        : null
                                    }
                                </div>
                            </Link>
                        )
                    })}
                </div>

                <Bar data={data} title={chartTitle}></Bar>

                <Transactions txs={txs} isRecent={true} setIsUpdated={setIsUpdated} />
            </div>
        </AppPageLayout>
    );
};

export default observer(Dashboard);