import { $authHost, $host } from "./index";

export default class SessionsService {
    static async create(email, password) {
        return $host.post('/api/v1/sessions', { email, password });
    }

    static async delete() {
        return $authHost.delete('/api/v1/sessions');
    }

    static async update() {
        return $host.put('/api/v1/sessions');
    }
}