import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import classes from './ResetPassword.module.scss'
import { Context } from '../../';
import SyncService from '../../http/SyncService';
import thumbnail1 from '../../assets/icons/auth/thumbnail1.png'
import { DASHBOARD_ROUTE, LOGIN_ROUTE } from '../../utils/consts';
import { SetAccessToken } from '../../utils/token';

import AuthPageContainer from '../../components/AuthPageContainer/AuthPageContainer';
import FilledPurpleFullWidthButton from '../../components/Common/Button/FilledPurpleFullWidthButton';
import ResetPasswordForm from '../../components/AuthPageContainer/TwoColumnLayout/ResetPasswordForm/ResetPasswordForm';
import TwoColumnLayout from '../../components/AuthPageContainer/TwoColumnLayout/TwoColumnLayout';

const ResetPassword = () => {
    const { user } = useContext(Context)

    const [queryParams] = useSearchParams()

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isSent, setIsSent] = useState(false)
    const [error, setError] = useState('')

    const clickHandler = async (event) => {
        event.preventDefault()

        if (password !== confirmPassword) {
            setError('Passwords do not match')
            return
        }

        SyncService.resetPassword(password, queryParams.get("code")).then(resp => {
            setIsSent(true)
            SetAccessToken(resp.data.access_token)
            user.setIsAuth(true)
            user.setUser(resp.data.user)
        }).catch(e => {
            setError(e.response.data.slug)
        })
    }

    return (
        <AuthPageContainer>
            <TwoColumnLayout thumbnail={thumbnail1}>
                <div className={classes.container}>
                    <div className={classes.header}>Password reset</div>

                    {!isSent ?
                        <>
                            <div className={classes.instruction}>
                                Please enter a new password for your Todoist account.<br /><br />
                                This will end all active sessions for your account and issue a new API token.
                            </div>

                            {error !== '' ? <div className={classes.error}>{error}</div> : null}

                            <ResetPasswordForm clickHandler={clickHandler}
                                value1={password} setValue1={setPassword}
                                value2={confirmPassword} setValue2={setConfirmPassword}
                            />
                        </>
                        :
                        <>
                            <div className={classes.instruction}>
                                Your password has been changed and we have logged you in!<br /><br />
                            </div>

                            <a href={DASHBOARD_ROUTE}><FilledPurpleFullWidthButton>Start using Trexp</FilledPurpleFullWidthButton></a>
                        </>
                    }

                    <hr />

                    <div className={classes.loginRef}>
                        <span><Link to={LOGIN_ROUTE}>Go to login</Link></span>
                    </div>
                </div>
            </TwoColumnLayout>
        </AuthPageContainer >
    )
};

export default observer(ResetPassword);