import { $authHost, $host } from "./index";

export default class SyncService {
    static async sendVerifyEmail() {
        return $authHost.post(`/api/v1/sync/email/verify/request`);
    }

    static async sendResetPassword(email) {
        return $host.post(`/api/v1/sync/password/reset/request`, { email });
    }

    static async resetPassword(password, code) {
        return $host.post(`/api/v1/sync/password/reset?code=${code}`, { password });
    }
}