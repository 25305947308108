export const GenerateRandomRGBColor = () => {
    let num = Math.round(0xffffff * Math.random())
    let r = num >> 16
    let g = (num >> 8) & 255
    let b = num & 255
    let a = 1

    return { r, g, b, a }
}

export const GenerateRandomRGBColors = (count) => {
    var arr = []

    for (var i = 0; i < count; i++) {
        let [r, g, b, a] = GenerateRandomRGBColor()
        arr.push('rgb(' + r + ', ' + g + ', ' + b + ', ' + a + ')')
    }

    return arr
}

export const parseRGBA = (rgbaString) => {
    const rgbaValues = rgbaString.substring(5, rgbaString.length - 1).split(",").map(parseFloat)

    if (rgbaValues.length === 4 && !rgbaValues.some(isNaN)) {
        const [r, g, b, a] = rgbaValues
        return { r, g, b, a }
    }

    return null
}
