import { useContext } from 'react';
import { Navigate, Route, Routes } from "react-router-dom";

import { Context } from '.';
import { privateRoutes, publicRoutes } from './routes';
import { DASHBOARD_ROUTE, LOGIN_ROUTE } from './utils/consts';

const AppRouter = () => {
    const { user } = useContext(Context)

    return (
        user.isAuth
            ?
            <Routes>
                {privateRoutes.map(route =>
                    <Route path={route.path} element={<route.Component />} key={route.path} />
                )}

                <Route path="*" element={<Navigate to={DASHBOARD_ROUTE} replace />} />
            </Routes>
            :
            <Routes>
                {publicRoutes.map(route =>
                    <Route path={route.path} element={<route.Component />} key={route.path} />
                )}

                <Route path="*" element={<Navigate to={LOGIN_ROUTE} replace />} />
            </Routes>
    );
};

export default AppRouter;
