import React from 'react';

import apple from '../../../../assets/icons/auth/apple.svg'
import facebook from '../../../../assets/icons/auth/facebook.svg'
import google from '../../../../assets/icons/auth/google.svg'
import classes from './ThirdPartyAuthButton.module.scss';
import { getGoogleUrl } from '../../../../utils/getGoogleUrl';

export const GOOGLE = "google"
export const FACEBOOK = "facebook"
export const APPLE = "apple"

const ThirdPartyAuthButton = ({ service }) => {
    const serviceMap = new Map()

    serviceMap.set(GOOGLE, { img: google, text: "Continue with Google", url: getGoogleUrl("/") })
    serviceMap.set(FACEBOOK, { img: facebook, text: "Continue with Facebook" })
    serviceMap.set(APPLE, { img: apple, text: "Continue with Apple" })

    return (
        <a href={serviceMap.get(service).url}>
            <button className={classes.button}>
                <div className={classes.content}>
                    <img src={serviceMap.get(service).img} alt='service-logo' style={{ width: '16px', fill: 'white' }} />
                    <span>{serviceMap.get(service).text}</span>
                </div>
            </button>
        </a>
    );
};

export default ThirdPartyAuthButton;