import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import classes from './Main.module.scss'
import { REGISTRATION_ROUTE } from '../../utils/consts';

import MainPageLayout from '../../components/PageLayout/MainPageLayout';
import FilledPurpleButton from '../../components/Common/Button/FilledPurpleButton';

const Main = () => {
    return (
        <MainPageLayout>
            <div className={classes.container}>
                <div className={classes.mainBanner}>
                    <div className={classes.content}>
                        <h1 className={classes.title}>The best way <br />to track your expenses</h1>

                        <p className={classes.description}>Effortlessly manage your money, track expenses. <br />
                            Take control of your financial future and experience stress-free money management.
                        </p>

                        <Link to={REGISTRATION_ROUTE}>
                            <FilledPurpleButton>Start for free</FilledPurpleButton>
                        </Link>
                    </div>
                </div>
            </div>
        </MainPageLayout>
    )
};

export default observer(Main);