import React, { useEffect, useState } from 'react';

import classes from './Modal.module.scss';

import FilledPurpleButton from '../../Common/Button/FilledPurpleButton';
import OutlinedPurpleButton from '../../Common/Button/OutlinedPurpleButton';
import OutlinedRedButton from '../../Common/Button/OutlinedRedButton';
import Input from '../../Common/Input/Input';

import { CATEGORY_TYPES, CATEGORY_TYPES_MAP } from '../../../utils/consts';
import ModalRemove from './ModalRemove';
import CategoriesService from '../../../http/CategoriesService';
import Select from '../../Common/Select/Select';

const ModalCategory = ({ setIsOpen, edit = false, categoryID }) => {
    const [error, setError] = useState()
    const [name, setName] = useState()
    const [categoryType, setCategoryType] = useState()
    const [isModalRemoveOpen, setIsModalRemoveOpen] = useState(false)

    useEffect(() => {
        if (edit) {
            CategoriesService.getByID(categoryID).then(resp => {
                setName(resp.data.category.name)
                setCategoryType(resp.data.category.type)
            })
        }
    }, [edit, categoryID])

    const onSaveHandler = async (event) => {
        event.preventDefault()

        let params = {
            name: name,
            type: categoryType,
        }

        let actionMethod = CategoriesService.create
        if (edit) {
            actionMethod = CategoriesService.update
            params = { ...params, ...{ id: categoryID } }
        }

        actionMethod(params).then(() => {
            setIsOpen(false)
        }).catch(e => {
            setError(e.response.data.slug)
        })
    }

    const removeClickHandler = () => {
        CategoriesService.delete(categoryID).then(() => {
            setIsModalRemoveOpen(false)
            setIsOpen(false)
        })
    }

    return (
        <>
            <div className={classes.darkBG} onClick={() => setIsOpen(false)} />

            <div className={classes.centered}>
                <div className={classes.modal}>

                    {isModalRemoveOpen && <ModalRemove setIsOpen={setIsModalRemoveOpen} removeFn={removeClickHandler} />}

                    <div className={classes.header}>
                        <h1 className={classes.heading}>{edit ? 'Edit category' : 'Add category'}</h1>
                        <h2 className={classes.error}>{error}</h2>
                    </div>

                    <div className={classes.inputs}>
                        <Select
                            label='Category type'
                            options={CATEGORY_TYPES}
                            onChange={e => setCategoryType(e.value)}
                            placeholder={edit ? CATEGORY_TYPES_MAP.get(categoryType) : 'Choose category type'}
                        />
                        <Input
                            label='Category name'
                            onChange={e => setName(e.target.value)}
                            value={name || ""}
                            type='text'
                            placeholder='Enter category name'
                        />
                    </div>

                    <div className={classes.actions}>
                        {edit ? <OutlinedRedButton onClick={() => setIsModalRemoveOpen(true)}>Delete</OutlinedRedButton> : null}
                        <OutlinedPurpleButton onClick={() => setIsOpen(false)}>Cancel</OutlinedPurpleButton>
                        <FilledPurpleButton onClick={onSaveHandler}>Save</FilledPurpleButton>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ModalCategory;