import React from 'react';
import ReactSelect from 'react-select';

import classes from './Select.module.scss';

const Select = (props) => {
    const styles = {
        control: base => ({
            ...base,
            padding: 0,
            minHeight: 0,
            backgroundColor: "transparent",
            fontSize: 16,
            fontWeight: 600,
            color: "#202020",
            border: "none",
            boxSizing: "border-box",
            outline: "none",
            "&:focus": {
                outline: "none",
            },
            "boxShadow": "none",
        }),
        input: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
            gridArea: "none",
            caretColor: "transparent",
        }),
        valueContainer: (base) => ({
            ...base,
            padding: 0,
        }),
        indicatorContainer: (base) => ({
            ...base,
            padding: 0,
        }),
        indicatorsContainer: (base) => ({
            ...base,
            alignItems: "initial",
            marginTop: -18,
        }),
        indicatorSeparator: (base) => ({
            ...base,
            display: "none",
        }),
        singleValue: (base) => ({
            ...base,
            margin: 0,
            padding: "0px 6px 8px 6px",
        }),
        placeholder: base => {
            return {
                ...base,
                color: "#929292",
                fontSize: 16,
                fontWeight: "normal",
                margin: 0,
                padding: "0px 6px 8px 6px",
            };
        },
        menu: base => ({
            ...base,
            fontSize: 16,
            color: "#202020",
            marginTop: -16,
            marginBottom: 0,
        }),
    }

    return (
        <div className={classes.container}>
            <span className={classes.label}>{props.label}</span>
            <ReactSelect {...props} styles={styles} />
        </div>
    );
};

export default Select;