import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut as DoughnutChartJS } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const Doughnut = ({ data, title }) => {
    const options = {
        responsive: true,
        aspectRatio: 1, // Set the aspect ratio (width:height)
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: title,
            },
        },
    };

    return (
        <DoughnutChartJS options={options} data={data} />
    );
};

export default Doughnut;