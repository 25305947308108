import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import classes from './Settings.module.scss'
import { Context } from '../..';
import CurrenciesService from '../../http/CurrenciesService';
import UsersService from '../../http/UsersService';
import SyncService from '../../http/SyncService';
import { PERIOD_TYPES } from '../../utils/consts';
import { RemoveAccessToken } from '../../utils/token';

import AppPageLayout from '../../components/PageLayout/AppPageLayout';
import ModalRemove from '../../components/PageLayout/Modal/ModalRemove';
import FilledPurpleButton from '../../components/Common/Button/FilledPurpleButton';
import OutlinedRedButton from '../../components/Common/Button/OutlinedRedButton';
import FilledPurpleSmallButton from '../../components/Common/Button/FilledPurpleSmallButton';
import Input from '../../components/Common/Input/Input';
import Select from '../../components/Common/Select/Select';
import Header from '../../components/PageLayout/Header/Header';

const Settings = () => {
    const { user } = useContext(Context);
    const [currencyOptions, setCurrencyOptions] = useState()
    const [email, setEmail] = useState('')
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [currency, setCurrency] = useState()
    const [period, setPeriod] = useState()
    const [info, setInfo] = useState()
    const [error, setError] = useState()
    const [isModalRemoveOpen, setIsModalRemoveOpen] = useState(false)

    useEffect(() => {
        CurrenciesService.getAll().then((resp) => {
            const options = []
            resp.data.currencies.forEach((curr) => {
                options.push({ label: `${curr.iso}`, value: curr.iso })
            });
            setCurrencyOptions(options)
        })
    }, [])

    const updateUserAccountHandler = () => {
        UsersService.update(email, oldPassword, newPassword, currency, period).then(() => {
            setInfo("Successfully updated")
            setError("")
        }).catch((e) => {
            setError(e.response.data.slug)
            setInfo("")
        })
    }

    const verifyEmailHandler = () => {
        SyncService.sendVerifyEmail().then(() => {
            setInfo("Verification code has been sent to your email")
            setError("")
        })
    }

    const navigate = useNavigate();

    const deleteAccountHandler = () => {
        UsersService.delete().then(() => {
            setIsModalRemoveOpen(false)
            RemoveAccessToken()
            user.setUser({})
            user.setIsAuth(false)
            navigate(0);
        })
    }

    return (
        <AppPageLayout header={
            <Header title='SETTINGS' />
        }>

            {isModalRemoveOpen && <ModalRemove setIsOpen={setIsModalRemoveOpen} removeFn={deleteAccountHandler} />}

            <div className={classes.content}>
                <div className={classes.section}>
                    <div className={classes.sectionName}>Account</div>

                    <div className={classes.block}>
                        {!user.user.email_verified
                            ? <FilledPurpleSmallButton onClick={verifyEmailHandler}>Verify</FilledPurpleSmallButton>
                            : null
                        }

                        {info !== ''
                            ? <div className={classes.infoMsg}>{info}</div>
                            : null
                        }

                        {error !== ''
                            ? <div className={classes.errorMsg}>{error}</div>
                            : null
                        }

                        <Input
                            label='Email'
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                            type='text'
                            placeholder={user.user.email}
                        />
                    </div>

                    <div className={classes.block}>
                        <Input
                            label='Old password'
                            onChange={e => setOldPassword(e.target.value)}
                            value={oldPassword}
                            type='password'
                            placeholder="Old Password"
                        />
                    </div>

                    <div className={classes.block}>
                        <Input
                            label='New password'
                            onChange={e => setNewPassword(e.target.value)}
                            value={newPassword}
                            type='password'
                            placeholder="New Password"
                        />
                    </div>
                </div>

                <div className={classes.section}>
                    <div className={classes.sectionName}>General</div>

                    <div className={classes.block}>
                        <div className={classes.title}>Currency</div>
                        <Select
                            label='Currency'
                            options={currencyOptions}
                            placeholder={user.user.currency}
                            onChange={(e) => setCurrency(e.value)}
                        />
                    </div>

                    <div className={classes.block}>
                        <div className={classes.title}>Period</div>
                        <Select
                            label='Period'
                            options={PERIOD_TYPES}
                            placeholder={user.user.period}
                            onChange={(e) => setPeriod(e.value)}
                        />
                    </div>
                </div>

                <div className={classes.buttons}>
                    <OutlinedRedButton onClick={() => setIsModalRemoveOpen(true)}>Delete account</OutlinedRedButton>
                    <FilledPurpleButton onClick={updateUserAccountHandler}>Save changes</FilledPurpleButton>
                </div>
            </div>
        </AppPageLayout >
    );
};

export default observer(Settings);