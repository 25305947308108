import { observer } from 'mobx-react-lite';
import { useState, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import classes from './Auth.module.scss'
import { Context } from '../../';

import FilledPurpleFullWidthButton from '../../components/Common/Button/FilledPurpleFullWidthButton';
import Input from '../../components/Common/Input/Input';

import AuthPageContainer from '../../components/AuthPageContainer/AuthPageContainer';
import TwoColumnLayout from '../../components/AuthPageContainer/TwoColumnLayout/TwoColumnLayout';
import UsersService from '../../http/UsersService';
import { LOGIN_ROUTE, REGISTRATION_ROUTE, AUTH_PASSWORD_ROUTE } from '../../utils/consts';
import thumbnail1 from '../../assets/icons/auth/thumbnail1.png'
import thumbnail2 from '../../assets/icons/auth/thumbnail2.png'
import ThirdPartyAuthButton, { APPLE, FACEBOOK, GOOGLE } from '../../components/AuthPageContainer/TwoColumnLayout/ThirdPartyAuthButton/ThirdPartyAuthButton';
import SessionsService from '../../http/SessionsService';
import { SetAccessToken } from '../../utils/token';

const Auth = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')

    const { user } = useContext(Context)
    const navigate = useNavigate()
    const location = useLocation()
    const isLogin = location.pathname === LOGIN_ROUTE

    const clickHandler = async (event) => {
        event.preventDefault()

        if (!isLogin) {
            UsersService.create(email, password).then(_ => {
                SessionsService.create(email, password).then(resp => {
                    SetAccessToken(resp.data.session.access_token)
                    user.setIsAuth(true)
                    navigate(0)
                }).catch(e => { setError(e.response.data.slug) })
            }).catch(e => { setError(e.response.data.slug) })
        } else {
            SessionsService.create(email, password).then(resp => {
                SetAccessToken(resp.data.session.access_token)
                user.setIsAuth(true)
                navigate(0)
            }).catch(e => { setError(e.response.data.slug) })
        }
    }

    return (
        <AuthPageContainer>
            <TwoColumnLayout thumbnail={isLogin ? thumbnail1 : thumbnail2}>
                <div className={classes.container}>
                    <div className={classes.header}>{isLogin ? "Log in" : "Sign up"}</div>

                    <div className={classes.thirdPartyBtns}>
                        <ThirdPartyAuthButton service={GOOGLE} />
                        <ThirdPartyAuthButton service={FACEBOOK} />
                        <ThirdPartyAuthButton service={APPLE} />
                    </div>

                    <hr />

                    {error !== '' ? <div className={classes.error}>{error}</div> : null}

                    <form onSubmit={clickHandler}>
                        <Input
                            label="Email"
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                            type='text'
                            placeholder="Enter your email..."
                        />
                        <Input
                            label="Password"
                            onChange={e => setPassword(e.target.value)}
                            value={password}
                            type='password'
                            placeholder="Enter your password..."
                        />
                        <FilledPurpleFullWidthButton>{isLogin ? 'Log In' : 'Sign up with Email'}</FilledPurpleFullWidthButton>
                    </form>

                    {isLogin
                        ?
                        <div className={classes.forgotPassword}>
                            <Link to={AUTH_PASSWORD_ROUTE}>Forgot your password?</Link>
                        </div>
                        :
                        null
                    }

                    <div className={classes.termsAndConds}>
                        By continuing with Google, Apple, or Email, you agree to Trexp's <Link>Terms of Service</Link> and <Link>Privacy Policy</Link>.
                    </div>

                    <hr />

                    <div className={classes.signupLoginRef}>
                        {isLogin
                            ? <span>Don't have an account? <Link to={REGISTRATION_ROUTE}>Sign up</Link></span>
                            : <span>Already signed up? <Link to={LOGIN_ROUTE}>Go to login</Link></span>
                        }
                    </div>
                </div>
            </TwoColumnLayout>
        </AuthPageContainer >
    )
};

export default observer(Auth);