import React from 'react';

import classes from './Input.module.scss';

const Input = (props) => {
    return (
        <div className={classes.container}>
            <span className={classes.label}>{props.label}</span>
            <input className={classes.input} {...props} />
        </div>
    )
}

export default Input;