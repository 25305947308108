import React from 'react';
import { Link } from 'react-router-dom';

import classes from './MainNavbar.module.scss';
import { LOGIN_ROUTE, MAIN_ROUTE, REGISTRATION_ROUTE } from '../../../utils/consts';

import FilledPurpleFullWidthButton from '../../Common/Button/FilledPurpleFullWidthButton';
import OutlinedGreyMenuButton from '../../Common/Button/OutlinedGreyMenuButton';
import OutlinedGreyButton from '../../Common/Button/OutlinedGreyButton';
import RegularLogo from '../../Common/Logo/RegularLogo';
import NavbarButtonBlack from '../../Common/NavbarButton/NavbarButtonBlack';

const MainNavbar = ({ isActive, setIsActive }) => {
    const menuLinksList = [
        { label: 'Feaures', link: '' },
        { label: 'Templates', link: '' },
        { label: 'For teams', link: '' },
        { label: 'Resources', link: '' },
        { label: 'Pricing', link: '' },
    ]

    return (
        <nav className={classes.navbar}>
            <div className={classes.left}>
                <Link to={MAIN_ROUTE}>
                    <RegularLogo />
                </Link>

                <div className={classes.menuBtn}>
                    <NavbarButtonBlack isActive={isActive} setIsActive={setIsActive} />
                </div>
            </div>

            <div className={`${classes.right} ${isActive && classes.opened}`}>
                <div className={classes.menuLinks}>
                    {menuLinksList.map((item, index) => {
                        return (
                            <Link to={item.link} key={index}>
                                <OutlinedGreyMenuButton>{item.label}</OutlinedGreyMenuButton>
                            </Link>
                        )
                    })}
                </div>

                <div className={classes.verticalDivider} />

                <div className={classes.authLinks}>
                    <Link to={LOGIN_ROUTE}>
                        <OutlinedGreyButton>Log in</OutlinedGreyButton>
                    </Link>

                    <Link to={REGISTRATION_ROUTE}>
                        <FilledPurpleFullWidthButton>Start for free</FilledPurpleFullWidthButton>
                    </Link>
                </div>
            </div >
        </nav >
    );
};

export default MainNavbar;