import { $authHost, $host } from "./index";

export default class UsersService {
    static async create(email, password) {
        return $host.post('/api/v1/users', { email, password });
    }

    static async get() {
        return $authHost.get('/api/v1/users');
    }

    static async update(email, old_password, new_password, currency, period) {
        return $authHost.patch('/api/v1/users', { email, old_password, new_password, currency, period });
    }

    static async delete() {
        return $authHost.delete(`/api/v1/users`);
    }
}