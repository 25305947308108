import React from 'react';
import classes from './AuthPageContainer.module.scss';
import Navbar from './Navbar/Navbar';

const AuthPageContainer = ({ children }) => {
    return (
        <div className={classes.container}>
            <div className={classes.subContainer}>
                <Navbar />

                {children}
            </div>
        </div>
    );
};

export default AuthPageContainer;