import React from 'react';

import classes from './ResetPasswordForm.module.scss';

import FilledPurpleFullWidthButton from '../../../Common/Button/FilledPurpleFullWidthButton';
import Input from '../../../Common/Input/Input';

const ResetPasswordForm = ({ clickHandler, value1, setValue1, value2, setValue2 }) => {
    return (
        <div className={classes.container}>
            <form onSubmit={clickHandler}>
                <Input
                    label='New password'
                    onChange={e => setValue1(e.target.value)}
                    value={value1}
                    type='password'
                    placeholder="Enter a new password"
                />
                <Input
                    label='Confirm password'
                    onChange={e => setValue2(e.target.value)}
                    value={value2}
                    type='password'
                    placeholder="Confirm your new password"
                />

                <FilledPurpleFullWidthButton>Reset my password</FilledPurpleFullWidthButton>
            </form>
        </div>
    );
};

export default ResetPasswordForm;