import React from 'react';
import { DatePicker } from 'antd';

const Date = (props) => {
    const styles = {
        height: "40px",
        width: "160px",
        padding: "0px 10px",
        border: "1px solid #ebebeb",
        backgroundColor: "transparent",
    }

    return (
        <DatePicker {...props} style={styles} />
    );
};

export default Date;