import React from 'react';

import classes from './TwoColumnLayout.module.scss';

const TwoColumnLayout = ({ thumbnail, children }) => {
    return (
        <div className={classes.container}>
            <div className={classes.left}>
                <div className={classes.content}>
                    {children}
                </div>
            </div>

            <div className={classes.right}>
                <img src={thumbnail} alt='auth' />
            </div>
        </div>
    );
};

export default TwoColumnLayout;