export const MAIN_ROUTE = '/'
export const LOGIN_ROUTE = '/auth/signin'
export const REGISTRATION_ROUTE = '/auth/signup'
export const AUTH_PASSWORD_ROUTE = '/auth/password'
export const AUTH_PASSWORD_RESET_ROUTE = '/auth/password/reset'

export const DASHBOARD_ROUTE = '/app'
export const WALLETS_ROUTE = '/app/wallets'
export const CATEGORIES_ROUTE = '/app/categories'
export const TRANSACTIONS_ROUTE = '/app/transactions'
export const STATISTICS_ROUTE = '/app/statistics'
export const SETTINGS_ROUTE = '/app/settings'

export const WALLET_TYPES = [
    { label: 'Bank wallet', value: 'bank_wallet_type' },
    { label: 'Cash wallet', value: 'cash_wallet_type' },
    { label: 'Crypto wallet', value: 'crypto_wallet_type' },
    { label: 'Other', value: 'other_wallet_type' },
]

export const WALLET_TYPES_MAP = new Map()
WALLET_TYPES.map((obj) => {
    return WALLET_TYPES_MAP.set(obj.value, obj.label);
})

export const INCOME_CATEGORY_TYPE = 'income_category_type'
export const EXPENSE_CATEGORY_TYPE = 'expense_category_type'

export const CATEGORY_TYPES = [
    { label: 'Income', value: INCOME_CATEGORY_TYPE },
    { label: 'Expense', value: EXPENSE_CATEGORY_TYPE },
]

export const CATEGORY_TYPES_MAP = new Map()
CATEGORY_TYPES.map((obj) => {
    return CATEGORY_TYPES_MAP.set(obj.value, obj.label);
})

export const INCOME_TX_TYPE = 'income_tx_type'
export const EXPENSE_TX_TYPE = 'expense_tx_type'

export const PERIOD_TYPES = [
    { label: 'Day', value: 'day' },
    { label: 'Week', value: 'week' },
    { label: 'Month', value: 'month' },
    { label: 'Year', value: 'year' },
]