import React from 'react';

import classes from './Modal.module.scss';

import OutlinedPurpleButton from '../../Common/Button/OutlinedPurpleButton';
import OutlinedRedButton from '../../Common/Button/OutlinedRedButton';


const ModalRemove = ({ setIsOpen, removeFn }) => {
    return (
        <>
            <div className={classes.darkBG} onClick={() => setIsOpen(false)} />

            <div className={classes.centered}>
                <div className={classes.modal}>

                    <div className={classes.header}>
                        <h1 className={classes.heading}>Are you sure you want to delete?</h1>
                    </div>

                    <div className={classes.actions}>
                        <OutlinedPurpleButton className={classes.cancelBtn} onClick={() => setIsOpen(false)}>Cancel</OutlinedPurpleButton>
                        <OutlinedRedButton onClick={() => {
                            removeFn()
                            setIsOpen(false)
                        }}>Delete</OutlinedRedButton>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ModalRemove;