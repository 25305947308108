import React from 'react';
import { Link } from 'react-router-dom';

import classes from './Navbar.module.scss';

import SmallLogo from '../../Common/Logo/SmallLogo';

import { MAIN_ROUTE } from '../../../utils/consts';

const Navbar = () => {
    return (
        <div className={classes.container}>
            <Link to={MAIN_ROUTE}>
                <SmallLogo />
            </Link>
        </div>
    );
};

export default Navbar;