import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import classes from './LeftSideMenu.module.scss';
import { DASHBOARD_ROUTE, WALLETS_ROUTE, TRANSACTIONS_ROUTE, CATEGORIES_ROUTE, STATISTICS_ROUTE } from '../../../utils/consts';
import dashboard from '../../../assets/icons/menu/dashboard.png'
import wallets from '../../../assets/icons/menu/wallets.png'
import txs from '../../../assets/icons/menu/transactions.png'
import categories from '../../../assets/icons/menu/categories.png'
import statistics from '../../../assets/icons/menu/statistics.png'

const LeftSideMenu = (props) => {
    const location = useLocation()

    const leftSideMenuItems = [
        { title: 'Dashboard', image: dashboard, url: DASHBOARD_ROUTE, active: location.pathname === DASHBOARD_ROUTE },
        { title: 'Wallets', image: wallets, url: WALLETS_ROUTE, active: location.pathname === WALLETS_ROUTE },
        { title: 'Transactions', image: txs, url: TRANSACTIONS_ROUTE, active: location.pathname === TRANSACTIONS_ROUTE },
        { title: 'Categories', image: categories, url: CATEGORIES_ROUTE, active: location.pathname === CATEGORIES_ROUTE },
        { title: 'Statistics', image: statistics, url: STATISTICS_ROUTE, active: location.pathname === STATISTICS_ROUTE },
    ]

    return (
        <div {...props} className={classes.container}>
            {leftSideMenuItems.map((item, i) => {
                return (
                    <Link to={item.url} key={i}>
                        <div className={`${classes.item} ${item.active && classes.itemSelected}`}>
                            <img src={item.image} alt="menu-item-logo" /><span>{item.title}</span>
                        </div>
                    </Link>
                )
            })}
        </div>
    );
};

export default LeftSideMenu;