import { $authHost } from "./index";

export default class CategoriesService {
    static async create(params) {
        return $authHost.post('/api/v1/categories', params);
    }

    static async getAll(type) {
        return $authHost.get(`/api/v1/categories?type=${type}`)
    }

    static async getByID(id) {
        return $authHost.get(`/api/v1/categories/${id}`);
    }

    static async update(params) {
        return $authHost.put('/api/v1/categories', params);
    }

    static async delete(id) {
        return $authHost.delete(`/api/v1/categories/${id}`);
    }
}