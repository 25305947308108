import React from 'react';

import classes from './Header.module.scss';

const Header = ({ title, button }) => {
    return (
        <div className={classes.container}>
            <div className={classes.title}>{title}</div>
            {button}
        </div>
    );
};

export default Header;