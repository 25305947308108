import React from 'react';

import classes from './Loader.module.scss';
import logo from '../../../assets/icons/logo.png'

const Loader = () => {
    return (
        <div className={classes.container}>
            <img src={logo} alt='logo' />
        </div>
    );
};

export default Loader;