import { $authHost } from "./index";

export default class WalletsService {
    static async create(params) {
        return $authHost.post('/api/v1/wallets', params);
    }

    static async edit(params) {
        return $authHost.put('/api/v1/wallets', params);
    }

    static async delete(id) {
        return $authHost.delete(`/api/v1/wallets/${id}`);
    }

    static async getByID(id) {
        return $authHost.get(`/api/v1/wallets/${id}`);
    }

    static async list() {
        return $authHost.get(`/api/v1/wallets`);
    }
}