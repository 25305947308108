import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import classes from './Transactions.module.scss'
import WalletsService from '../../http/WalletsService';
import TransactionsService from '../../http/TransactionsService';
import CategoriesService from '../../http/CategoriesService';
import { GetFormattedDate } from '../../utils/date';
import { INCOME_CATEGORY_TYPE } from '../../utils/consts';
import { EXPENSE_CATEGORY_TYPE } from '../../utils/consts';

import AppPageLayout from '../../components/PageLayout/AppPageLayout';
import ModalTx from '../../components/PageLayout/Modal/ModalTx';
import TransactionsView from '../../components/PageLayout/Transactions/Transactions';
import Select from '../../components/Common/Select/Select';
import Pagination from '../../components/Common/Pagination/Pagination';
import DateRange from '../../components/Common/DateRange/DateRange';
import Header from '../../components/PageLayout/Header/Header';
import FilledPurpleSmallButton from '../../components/Common/Button/FilledPurpleSmallButton';

const Transactions = () => {
    const [isModalTxOpen, setIsModalTxOpen] = useState(false);
    const [sort, setSort] = useState(true)
    const [wallet, setWallet] = useState('')
    const [category, setCategory] = useState('')
    const [txType, setTxType] = useState('')
    const [dateFrom, setDateFrom] = useState('')
    const [dateTo, setDateTo] = useState('')
    const [txs, setTxs] = useState([])
    const [isUpdated, setIsUpdated] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();

    useEffect(() => {
        TransactionsService.getAll(currentPage, 10, sort, wallet, category, txType, dateFrom, dateTo).then(resp => {
            setTxs(resp.data.txs)
            setTotalPages(resp.data.total_pages)
        })

        setIsUpdated(false)
    }, [sort, wallet, category, txType, dateTo, dateFrom, isUpdated, currentPage])

    const sortOptions = [
        { value: true, label: "Newest" },
        { value: false, label: "Oldest" },
    ]

    const txTypeOptions = [
        { value: "", label: "All" },
        { value: "income_tx_type", label: "Income" },
        { value: "expense_tx_type", label: "Expense" },
    ]


    const walletOptions = [{ label: 'All', value: '' }]
    const categoryOptions = [{ label: 'All', value: '' }]

    useEffect(() => {
        WalletsService.list().then((resp) => {
            resp.data.wallets.forEach((wallet) => {
                walletOptions.push({ label: `${wallet.name} (${wallet.currency})`, value: wallet.id })
            });
        })

        const categoryTypes = [INCOME_CATEGORY_TYPE, EXPENSE_CATEGORY_TYPE]

        categoryTypes.map(async (categoryType) => {
            const resp = await CategoriesService.getAll(categoryType);
            resp.data.categories.forEach((categ) => {
                categoryOptions.push({ label: categ.name, value: categ.id });
            });
        })
    })

    return (
        <AppPageLayout
            header={<Header
                title='TRANSACTIONS'
                button={<FilledPurpleSmallButton onClick={() => setIsModalTxOpen(true)}>Add transaction</FilledPurpleSmallButton>}
            />}
        >
            {isModalTxOpen && <ModalTx setIsOpen={setIsModalTxOpen} setIsUpdated={setIsUpdated} />}

            <div className={classes.content}>
                <div className={classes.contentHeader}>
                    <Select
                        label="Sort by"
                        options={sortOptions}
                        onChange={e => setSort(e.value)}
                        placeholder="Choose sort"
                    />
                    <Select
                        label="Wallet"
                        options={walletOptions}
                        onChange={e => setWallet(e.value)}
                        placeholder="Choose wallet"
                    />
                    <Select
                        label="Category"
                        options={categoryOptions}
                        onChange={e => setCategory(e.value)}
                        placeholder="Choose category"
                    />
                    <Select
                        label="Type"
                        options={txTypeOptions}
                        onChange={e => setTxType(e.value)}
                        placeholder="Choose type"
                    />
                    <DateRange onChange={(date) => {
                        if (date == null) {
                            setDateFrom('')
                            setDateTo('')
                        } else {
                            setDateFrom(GetFormattedDate(date[0]?.$D, date[0]?.$M, date[0]?.$y))
                            setDateTo(GetFormattedDate(date[1]?.$D, date[1]?.$M, date[1]?.$y))
                        }
                    }} />
                </div>

                <TransactionsView txs={txs} setIsUpdated={setIsUpdated} />
                <Pagination
                    totalPages={totalPages}
                    activePage={currentPage}
                    paginate={(page) => setCurrentPage(page)}
                />
            </div>
        </AppPageLayout >
    );
};

export default observer(Transactions);