// e.i monthsTotals = [100, 200, 240, 50, 1000, 222, 434, 900, 50, 4322, 10, 44]
export const calcExpIncPercChange = (monthsTotals) => {
    const currentDate = new Date()
    const monthNumber = currentDate.getMonth()

    let lastMonthAmount = Number(monthsTotals[monthNumber - 1]) ? Number(monthsTotals[monthNumber - 1]) : Number(monthsTotals[monthNumber])
    let currentMonth = Number(monthsTotals[monthNumber])

    let percentage = ((currentMonth - lastMonthAmount) / lastMonthAmount) * 100

    return {
        isProfit: percentage > 0,
        percentage: `${(percentage <= 0 ? "" : "+")}${Math.round(percentage ? percentage : 0)}%`,
        display: monthNumber !== 0,
    }
}

export const calcTotalBalancePercChange = (curTotalBalance, incMonthTotals, expMonthsTotals) => {
    const currentDate = new Date()
    const monthNumber = currentDate.getMonth()

    let currentMonthExpense = Number(expMonthsTotals[monthNumber])
    let currentMonthIncome = Number(incMonthTotals[monthNumber])
    let lastMonthTotalBalance = ((Number(curTotalBalance) + currentMonthExpense) - currentMonthIncome)

    let percentage = ((curTotalBalance - lastMonthTotalBalance) / lastMonthTotalBalance) * 100

    return {
        isProfit: percentage > 0,
        percentage: `${(percentage <= 0 ? "" : "+")}${Math.round(percentage ? percentage : 0)}%`,
        display: monthNumber !== 0,
    }
}