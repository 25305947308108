import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import classes from './ForgotPassword.module.scss'
import SyncService from '../../http/SyncService';
import thumbnail1 from '../../assets/icons/auth/thumbnail1.png'
import { LOGIN_ROUTE } from '../../utils/consts';

import AuthPageContainer from '../../components/AuthPageContainer/AuthPageContainer';
import ForgotPasswordForm from '../../components/AuthPageContainer/TwoColumnLayout/ForgotPasswordForm/ForgotPasswordForm';
import TwoColumnLayout from '../../components/AuthPageContainer/TwoColumnLayout/TwoColumnLayout';

const ForgotPassword = () => {
    const [email, setEmail] = useState('')
    const [isSent, setIsSent] = useState(false)
    const [error, setError] = useState('')
    
    const clickHandler = async (event) => {
        event.preventDefault()

        SyncService.sendResetPassword(email).then(_ => {
            setIsSent(true)
        }).catch(e => {
            setError(e.response.data.slug)
        })
    }

    return (
        <AuthPageContainer>
            <TwoColumnLayout thumbnail={thumbnail1}>
                <div className={classes.container}>
                    <div className={classes.header}>Forgot your password?</div>

                    {!isSent ?
                        <>
                            <div className={classes.instruction}>
                                To reset your password, please enter the email address of your Todoist account.
                            </div>

                            {error !== '' ? <div className={classes.error}>{error}</div> : null}

                            <ForgotPasswordForm clickHandler={clickHandler} value={email} setValue={setEmail} />
                        </>
                        :
                        <div className={classes.instruction}>
                            You've been emailed a password reset link. Please check your inbox.
                        </div>
                    }

                    <hr />

                    <div className={classes.loginRef}>
                        <span><Link to={LOGIN_ROUTE}>Go to login</Link></span>
                    </div>
                </div>
            </TwoColumnLayout>
        </AuthPageContainer >
    )
};

export default observer(ForgotPassword);