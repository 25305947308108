import { Link } from 'react-router-dom';
import { useState } from 'react';

import ModalTx from '../../PageLayout/Modal/ModalTx';

import { TRANSACTIONS_ROUTE } from '../../../utils/consts';
import { formatMoney } from '../../../utils/money';
import classes from './Transactions.module.scss';

const Transactions = ({ txs, isRecent = false, setIsUpdated }) => {
    const [isModalTxOpen, setIsModalTxOpen] = useState(false);
    const [txID, setTxID] = useState();

    const editClickHandler = (txID) => {
        return () => {
            setTxID(txID)
            setIsModalTxOpen(true)
        }
    }

    const txTypeMap = new Map()
    txTypeMap.set('income_tx_type', 'Income')
    txTypeMap.set('expense_tx_type', 'Expense')

    return (
        <div className={classes.container}>
            {isModalTxOpen && <ModalTx setIsOpen={setIsModalTxOpen} edit={true} txID={txID} setIsUpdated={setIsUpdated} />}

            <div className={classes.subContainer}>
                <div className={classes.header}>
                    <div className={classes.title}>Recent Transactions</div>
                    {isRecent
                        ?
                        <div className={classes.seeAllBtn}>
                            <Link to={TRANSACTIONS_ROUTE}>See All Transactions</Link>
                        </div>
                        :
                        null
                    }
                </div>
                <div className={classes.transactions}>
                    <table>
                        <thead>
                            <tr>
                                <th className={classes.disappearThird}>No</th>
                                <th>Wallet</th>
                                <th className={classes.disappearSecond}>Category</th>
                                <th className={classes.disappearSecond}>Type</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th className={classes.disappearFirst}>Comment</th>
                            </tr>
                        </thead>

                        <tbody>
                            {txs?.map((tx, i) => {
                                return (
                                    <tr key={tx.id} onClick={editClickHandler(tx.id)}>
                                        <td className={classes.disappearThird}>{i + 1}</td>
                                        <td>{tx.wallet}</td>
                                        <td className={classes.disappearSecond}>{tx.category}</td>
                                        <td className={classes.disappearSecond}>{txTypeMap.get(tx.type)}</td>
                                        <td>{tx.date}</td>
                                        {tx.type === 'income_tx_type'
                                            ? <td className={classes.income}>+{formatMoney(tx.currency, tx.amount)}</td>
                                            : <td className={classes.expense}>-{formatMoney(tx.currency, tx.amount)}</td>
                                        }
                                        <td className={classes.disappearFirst}>{tx.comment}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table >
                </div>
            </div>
        </div>
    );
};

export default Transactions;