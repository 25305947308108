import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import classes from './Wallets.module.scss'
import WalletsService from '../../http/WalletsService';
import StatsService from '../../http/StatsService';
import { WALLET_TYPES } from '../../utils/consts';
import { formatMoney } from '../../utils/money';
import { parseRGBA } from '../../utils/colors';

import ModalWallet from '../../components/PageLayout/Modal/ModalWallet';
import Header from '../../components/PageLayout/Header/Header';
import AppPageLayout from '../../components/PageLayout/AppPageLayout';
import FilledPurpleSmallButton from '../../components/Common/Button/FilledPurpleSmallButton';
import Doughnut from '../../components/Common/Doughnut/Doughnut';

const Wallets = () => {
    const [walletID, setWalletID] = useState()
    const [edit, setEdit] = useState()
    const [wallets, setWallets] = useState(new Map())
    const [walletsTotals, setWalletsTotals] = useState(new Map())
    const [isModalWalletOpen, setIsModalWalletOpen] = useState(false)

    // for charts
    const [walletLabels, setWalletLabels] = useState([])
    const [walletBalances, setWalletBalances] = useState([])
    const [walletColors, setWalletColors] = useState([])

    useEffect(() => {
        WalletsService.list()
            .then((resp) => {
                const walletsMap = new Map()

                resp.data.wallets.forEach((obj) => {
                    let arr = walletsMap.get(obj.type)

                    if (arr === undefined) {
                        arr = [obj]
                        walletsMap.set(obj.type, arr)
                    } else {
                        arr.push(obj)
                    }
                });

                setWallets(walletsMap)
            });

        StatsService.getWalletsTotals().then(resp => {
            const walletsTotals = new Map()

            const labels = []
            const balances = []
            const colors = []
            resp.data.wallets_totals.map(wallet => {
                labels.push(wallet.name)
                balances.push(wallet.balance_in_user_curr)
                colors.push(wallet.color)
                return walletsTotals.set(wallet.id, wallet)
            })

            setWalletsTotals(walletsTotals)
            setWalletLabels(labels)
            setWalletBalances(balances)
            setWalletColors(colors)
        })
    }, [isModalWalletOpen]);

    const handleWalletItemOnClick = walletID => () => {
        setWalletID(walletID)
        setEdit(true)
        setIsModalWalletOpen(true)
    }

    const handleAddWalletOnClick = () => {
        setEdit(false)
        setIsModalWalletOpen(true)
    }

    const data = {
        labels: walletLabels,
        datasets: [
            {
                data: walletBalances,
                backgroundColor: walletColors,
            },
        ],
    };

    return (
        <AppPageLayout
            header={<Header
                title='WALLETS'
                button={<FilledPurpleSmallButton onClick={handleAddWalletOnClick}>Add wallet</FilledPurpleSmallButton>}
            />}
        >
            {isModalWalletOpen && <ModalWallet setIsOpen={setIsModalWalletOpen} edit={edit} walletID={walletID} />}

            <div className={classes.container}>
                <div className={classes.left}>
                    {WALLET_TYPES.map((item) => {
                        return (
                            <div className={classes.walletsBlock} key={item.value}>
                                <div className={classes.title}>{item.label}</div>
                                <div className={classes.list}>
                                    {wallets.get(item.value)
                                        ?
                                        wallets.get(item.value)?.map((wallet) => {
                                            let rgba = parseRGBA(wallet.color)
                                            return (
                                                <div
                                                    className={classes.item}
                                                    key={wallet.id}
                                                    onClick={handleWalletItemOnClick(wallet.id)}
                                                    style={{
                                                        boxShadow: `1px 1px 2px 2px rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a})`,
                                                    }}
                                                >
                                                    <div className={classes.leftBlock}>
                                                        <div className={classes.name}>{wallet.name}</div>
                                                        <div className={classes.intialBalance}>Initial balance: {wallet.initial_balance}</div>
                                                    </div>
                                                    <div className={classes.rightBlock}>
                                                        <div className={classes.balance}>{formatMoney(wallet.currency, wallet.balance)}</div>
                                                        <div className={classes.expense}>Spent: {formatMoney(wallet.currency, walletsTotals.get(wallet.id)?.expense)}</div>
                                                        <div className={classes.income}>Earned: {formatMoney(wallet.currency, walletsTotals.get(wallet.id)?.income)}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className={classes.emptyItem}>None added</div>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div className={classes.right}>
                    <Doughnut data={data} title="Wallets distribution" />
                </div>
            </div>
        </AppPageLayout >
    );
};

export default observer(Wallets);