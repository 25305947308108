const monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const GetFormattedDate = (day, month, year) => {
    let formattedMonth = monthNamesShort[month]

    return `${day} ${formattedMonth} ${year}`
}

export const GetTodayDate = () => {
    let date = new Date()

    return GetFormattedDate(date.getDate(), date.getMonth(), date.getFullYear())
}
