import React from 'react';

import classes from './NavbarButton.module.scss';

const NavbarButtonBlack = ({ isActive, setIsActive }) => {
    return (
        <div
            type="button"
            className={`${classes.btnHamburgerBlack} ${isActive ? classes.btnHamburgerOpened : ''} `}
            data-action="nav-toggle"
            onClick={() => setIsActive(!isActive)}
        >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}

export default NavbarButtonBlack;