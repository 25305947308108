import React, { useEffect, useState } from 'react';

import classes from './Modal.module.scss';

import FilledPurpleButton from '../../Common/Button/FilledPurpleButton';
import OutlinedPurpleButton from '../../Common/Button/OutlinedPurpleButton';
import OutlinedRedButton from '../../Common/Button/OutlinedRedButton';
import Input from '../../Common/Input/Input';
import Select from '../../Common/Select/Select';
import RadioButton from '../../Common/RadioButton/RadioButton';

import TransactionsService from '../../../http/TransactionsService';
import WalletsService from '../../../http/WalletsService';
import CategoriesService from '../../../http/CategoriesService';
import { EXPENSE_CATEGORY_TYPE, EXPENSE_TX_TYPE, INCOME_CATEGORY_TYPE, INCOME_TX_TYPE } from '../../../utils/consts';
import { GetFormattedDate, GetTodayDate } from '../../../utils/date';
import Date from '../../Common/Date/Date';
import ModalRemove from './ModalRemove';

const getTxTypeOptions = () => {
    return {
        first: { label: "Expense", value: EXPENSE_TX_TYPE },
        second: { label: "Income", value: INCOME_TX_TYPE },
    }
}

const ModalTx = ({ setIsOpen, edit = false, txID, setIsUpdated }) => {
    const [wallet, setWallet] = useState()
    const [txType, setTxType] = useState(EXPENSE_TX_TYPE)
    const [amount, setAmount] = useState()
    const [category, setCategory] = useState()
    const [comment, setComment] = useState()
    const [date, setDate] = useState()
    const [walletOptions, setWalletOptions] = useState([])
    const [categoryOptions, setCategoryOptions] = useState([])
    const [tx, setTx] = useState()
    const [error, setError] = useState()
    const [isModalRemoveOpen, setIsModalRemoveOpen] = useState(false)

    useEffect(() => {
        if (edit) {
            TransactionsService.getByID(txID).then(resp => {
                setTx(resp.data.transaction)
                setComment(resp.data.transaction?.comment)
                setAmount(resp.data.transaction?.amount)
                setTxType(resp.data.transaction?.type)
            })
        }
    }, [edit, txID])

    const onSaveHandler = async (event) => {
        event.preventDefault()

        let txDate = date ? date : GetTodayDate()
        if (edit) {
            txDate = date ? date : tx?.date
        }

        let params = {
            amount: amount,
            comment: comment,
            category_id: category ? category : tx?.category_id,
            date: txDate,
            type: txType,
            wallet_id: wallet ? wallet : tx?.wallet_id
        }

        let actionMethod = TransactionsService.create
        if (edit) {
            actionMethod = TransactionsService.update
            params = { ...params, ...{ id: txID } }
        }

        actionMethod(params).then(() => {
            setIsOpen(false)
            setIsUpdated(true)
        }).catch(e => {
            setError(e.response.data.slug)
        })
    }


    useEffect(() => {
        WalletsService.list()
            .then((resp) => {
                let options = []
                resp.data.wallets.forEach((w) => {
                    options.push({ label: `${w.name} (${w.currency})`, value: w.id })
                });

                setWalletOptions(options);
            });
    }, []);

    useEffect(() => {
        let categoryType = EXPENSE_CATEGORY_TYPE
        if (txType === INCOME_TX_TYPE) {
            categoryType = INCOME_CATEGORY_TYPE
        }

        CategoriesService.getAll(categoryType)
            .then((resp) => {
                let options = []
                resp.data.categories.forEach((c) => {
                    options.push({ label: c.name, value: c.id })
                });

                setCategoryOptions(options)
            });
    }, [txType]);

    const removeClickHandler = () => {
        TransactionsService.delete(txID).then(() => {
            setIsModalRemoveOpen(false)
            setIsUpdated(true)
            setIsOpen(false)
        })
    }

    return (
        <>
            <div className={classes.darkBG} onClick={() => setIsOpen(false)} />

            <div className={classes.centered}>
                <div className={classes.modal}>

                    {isModalRemoveOpen && <ModalRemove setIsOpen={setIsModalRemoveOpen} removeFn={removeClickHandler} />}

                    <div className={classes.header}>
                        <h1 className={classes.heading}>{edit ? 'Edit transaction' : 'Add transaction'}</h1>
                        <h2 className={classes.error}>{error}</h2>
                    </div>

                    <div className={classes.inputs}>
                        <RadioButton
                            options={getTxTypeOptions()}
                            stateFunc={setTxType}
                            currValue={txType}
                        />
                        <Date
                            onChange={(date) => setDate(GetFormattedDate(date.$D, date.$M, date.$y))}
                            format="DD MMM YYYY"
                            placeholder={edit ? tx?.date : 'Today'}
                        />
                        <Select
                            label="Wallet"
                            options={walletOptions}
                            onChange={e => setWallet(e.value)}
                            placeholder={edit ? tx?.wallet : 'Choose wallet'}
                        />
                        <Select
                            label="Category"
                            options={categoryOptions}
                            onChange={e => setCategory(e.value)}
                            placeholder={edit ? tx?.category : 'Choose category'}
                        />
                        <Input
                            label="Amount"
                            onChange={e => setAmount(e.target.value)}
                            value={amount || ""}
                            type='text'
                            placeholder='Enter amount'
                        />
                        <Input
                            label="Comment"
                            onChange={e => setComment(e.target.value)}
                            value={comment || ""}
                            type='text'
                            placeholder='Enter comment'
                        />
                    </div>

                    <div className={classes.actions}>
                        {edit ? <OutlinedRedButton onClick={() => setIsModalRemoveOpen(true)}>Delete</OutlinedRedButton> : null}
                        <OutlinedPurpleButton onClick={() => setIsOpen(false)}>Cancel</OutlinedPurpleButton>
                        <FilledPurpleButton onClick={onSaveHandler}>Save</FilledPurpleButton>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ModalTx;