import React, { useState } from 'react';

import classes from './PageLayout.module.scss';

import MainNavbar from './MainNavbar/MainNavbar';

const MainPageLayout = ({ children }) => {
    const [isNavbarActive, setIsNavbarActive] = useState(false)

    return (
        <div className={classes.mainPageContainer}>
            <MainNavbar
                isActive={isNavbarActive}
                setIsActive={setIsNavbarActive}
            />

            <div
                className={`${isNavbarActive ? classes.darkBackground : ''}`}
                onClick={() => setIsNavbarActive(false)}
            ></div>

            {children}
        </div>
    );
};

export default MainPageLayout;