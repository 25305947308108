import React, { useEffect, useState } from 'react';
import { SketchPicker } from "react-color";

import classes from './Modal.module.scss';

import FilledPurpleButton from '../../Common/Button/FilledPurpleButton';
import OutlinedPurpleButton from '../../Common/Button/OutlinedPurpleButton';
import OutlinedRedButton from '../../Common/Button/OutlinedRedButton';
import Input from '../../Common/Input/Input';

import ModalRemove from './ModalRemove';

import WalletsService from '../../../http/WalletsService';
import CurrenciesService from '../../../http/CurrenciesService';
import { WALLET_TYPES, WALLET_TYPES_MAP } from '../../../utils/consts';
import { Checkbox } from 'antd';
import { GenerateRandomRGBColor, parseRGBA } from '../../../utils/colors';
import Select from '../../Common/Select/Select';

const ModalWallet = ({ setIsOpen, edit = false, walletID }) => {
    const [error, setError] = useState()
    const [name, setName] = useState()
    const [currency, setCurrency] = useState()
    const [walletType, setWalletType] = useState()
    const [initialBalance, setInitialBalance] = useState()
    const [currencyOpts, setCurrencyOpts] = useState([])
    const [includeInTotal, setIncludeInTotal] = useState(true)
    const [wallet, setWallet] = useState()
    const [isModalRemoveOpen, setIsModalRemoveOpen] = useState(false)
    const [colorPickerShow, setColorPickerShow] = useState(false)
    const [sketchPickerColor, setSketchPickerColor] = useState({});

    useEffect(() => {
        if (edit) {
            WalletsService.getByID(walletID).then(resp => {
                setWallet(resp.data.wallet)
                setName(resp.data.wallet.name)
                setCurrency(resp.data.wallet.currency)
                setInitialBalance(resp.data.wallet.initial_balance)
                setWalletType(resp.data.wallet.type)
                setIncludeInTotal(resp.data.wallet.include_in_total)

                let rgba = parseRGBA(resp.data.wallet.color)
                if (rgba !== null) {
                    setSketchPickerColor({
                        r: rgba.r,
                        g: rgba.g,
                        b: rgba.b,
                        a: rgba.a,
                    })
                }

            })
        } else {
            let rgba = GenerateRandomRGBColor()
            if (rgba !== null) {
                setSketchPickerColor({
                    r: rgba.r,
                    g: rgba.g,
                    b: rgba.b,
                    a: rgba.a,
                })
            }
        }
    }, [edit, walletID])

    const onSaveHandler = async (event) => {
        event.preventDefault()

        let params = {
            name: name,
            currency: currency,
            type: walletType,
            initial_balance: initialBalance,
            include_in_total: includeInTotal,
            color: `rgba(${sketchPickerColor.r}, ${sketchPickerColor.g}, ${sketchPickerColor.b}, ${sketchPickerColor.a})`,
        }

        let actionMethod = WalletsService.create
        if (edit) {
            actionMethod = WalletsService.edit
            params = { ...params, ...{ id: walletID } }
        }

        actionMethod(params).then(() => {
            setIsOpen(false)
        }).catch(e => {
            setError(e.response.data.slug)
        })
    }

    useEffect(() => {
        CurrenciesService.getAll()
            .then((resp) => {
                const modifiedData = resp.data.currencies.map((item) => ({
                    label: `${item.iso} – ${item.name}`,
                    value: item.iso
                }));
                setCurrencyOpts(modifiedData);
            });
    }, []);

    const removeClickHandler = () => {
        WalletsService.delete(walletID).then(() => {
            setIsModalRemoveOpen(false)
            setIsOpen(false)
        }).catch(e => {
            setError(e.response.data.slug)
        })
    }

    const includeInTotalOnChange = (e) => {
        setIncludeInTotal(!e.target.checked)
    }

    return (
        <>
            <div className={classes.darkBG} onClick={() => setIsOpen(false)} />

            <div className={classes.centered} >
                <div className={classes.modal}  >

                    {isModalRemoveOpen && <ModalRemove setIsOpen={setIsModalRemoveOpen} removeFn={removeClickHandler} />}

                    <div className={classes.header}>
                        <h1 className={classes.heading}>{edit ? 'Edit wallet' : 'Add wallet'}</h1>
                        <h2 className={classes.error}>{error}</h2>
                    </div>

                    <div className={classes.inputs}>
                        <Select
                            label="Wallet type"
                            options={WALLET_TYPES}
                            onChange={e => setWalletType(e.value)}
                            placeholder={edit ? WALLET_TYPES_MAP.get(wallet?.type) : 'Choose wallet type'}
                        />
                        <Select
                            label="Currency"
                            options={currencyOpts}
                            onChange={e => setCurrency(e.value)}
                            placeholder={edit ? wallet?.currency : 'Choose currency'}
                        />
                        <Input
                            label="Wallet name"
                            onChange={e => setName(e.target.value)}
                            value={name || ""}
                            type='text'
                            placeholder='Enter wallet name'
                        />
                        <Input
                            label="Initial balance"
                            onChange={e => setInitialBalance(e.target.value)}
                            value={initialBalance || ""}
                            type='text'
                            placeholder='Enter initial balance'
                        />

                    </div>

                    <div className={classes.options}>
                        <Checkbox onChange={includeInTotalOnChange} checked={!includeInTotal}>Don't include in Total</Checkbox>

                        <div className={classes.colorPicker}>
                            <div
                                className={classes.colorPickerInput}
                                style={{
                                    backgroundColor: `rgba(${sketchPickerColor.r},${sketchPickerColor.g},${sketchPickerColor.b},${sketchPickerColor.a})`,
                                }}
                                onClick={() => setColorPickerShow(!colorPickerShow)}
                            ></div>

                            {colorPickerShow ?
                                <SketchPicker
                                    onChange={(color) => { setSketchPickerColor(color.rgb); }}
                                    color={sketchPickerColor}
                                    className={classes.colorPickerInputActive}
                                />
                                : null
                            }
                        </div>
                    </div>


                    <div className={classes.actions}>
                        {edit ? <OutlinedRedButton onClick={() => setIsModalRemoveOpen(true)}>Delete</OutlinedRedButton> : null}
                        <OutlinedPurpleButton onClick={() => setIsOpen(false)}>Cancel</OutlinedPurpleButton>
                        <FilledPurpleButton onClick={onSaveHandler}>Save</FilledPurpleButton>
                    </div>
                </div>
            </div >
        </>
    );
};

export default ModalWallet;