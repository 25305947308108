import { $authHost } from "./index";

export default class TransactionsService {
    static async create(tx) {
        let params = { "transactions": [tx] }

        return $authHost.post(`/api/v1/transactions`, params);
    }

    static async getAll(page, limit, recent, wallet_id, category_id, tx_type, date_from, date_to) {
        return $authHost.get(`/api/v1/transactions?page=${page}&limit=${limit}&recent=${recent}&wallet_id=${wallet_id}&category_id=${category_id}&tx_type=${tx_type}&date_from=${date_from}&date_to=${date_to}`);
    }

    static async getByID(id) {
        return $authHost.get(`/api/v1/transactions/${id}`);
    }

    static async update(params) {
        return $authHost.put(`/api/v1/transactions`, params);
    }

    static async delete(id) {
        return $authHost.delete(`/api/v1/transactions/${id}`);
    }
}