export const formatMoney = (currency, amount) => {
    let res = parseFloat(amount).toLocaleString('en-US', {
        style: 'currency',
        currency: currency,
    })

    if (res.includes('NaN')) {
        return 0
    }

    return res
}