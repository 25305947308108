import React from 'react';

import classes from './Logo.module.scss';

import logo from '../../../assets/icons/logo.png'

const SmallLogo = () => {
    return (
        <div className={classes.smallLogo}>
            <img className={classes.logoImg} src={logo} alt='logo' />
            <span className={classes.logoText}>trexp</span>
        </div>
    )
};

export default SmallLogo;