import axios from "axios";
import SessionsService from "./SessionsService";
import { GetAccessToken, SetAccessToken } from "../utils/token";

const $host = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true, // to include cookies while requests
})

const $authHost = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
})

$authHost.interceptors.request.use((config) => {
    config.headers.authorization = `Bearer ${GetAccessToken()}`
    return config;
})

$authHost.interceptors.response.use((config) => {
    return config;
}, async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && error.config && !originalRequest._isRetry) {
        originalRequest._isRetry = true

        if (error.response.status === 401) {
            const response = await SessionsService.update()
            SetAccessToken(response.data.session.access_token)
            return $authHost.request(originalRequest)
        }
    }

    throw error;
})

export { $host, $authHost }
