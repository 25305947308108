import React from 'react';

import classes from './RadioButton.module.scss';

const RadioButton = ({ options, stateFunc, currValue }) => {
    return (
        <div onChange={e => stateFunc(e.target.value)} className={classes.container}>
            <input
                type="radio"
                className={classes.input}
                name="view"
                value={options.first.value}
                id="first"
                checked={options.first.value === currValue ? true : ''}
                onChange={() => { }}
            />
            <label htmlFor="first" className={`${classes.label} ${classes.labelOff}`}>{options.first.label}</label>

            <input
                type="radio"
                className={classes.input}
                name="view"
                value={options.second.value}
                id="second"
                checked={options.second.value === currValue ? true : ''}
                onChange={() => { }}
            />
            <label htmlFor="second" className={`${classes.label} ${classes.labelOn}`}>{options.second.label}</label>

            <span className={classes.selection}></span>
        </div>

    );
};

export default RadioButton;