import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { BrowserRouter } from "react-router-dom";

import './styles/styles.scss';
import { Context } from '.';
import Router from './router';
import UsersService from './http/UsersService';
import { SetAccessToken } from './utils/token';
import { setCookie } from './utils/cookie';

import Loader from './components/Common/Loader/Loader';

const App = () => {
  const { user } = useContext(Context)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const accessToken = new URLSearchParams(window.location.hash.slice(1)).get('access_token');
    if (accessToken !== null) {
      SetAccessToken(accessToken)
    }
    const refreshToken = new URLSearchParams(window.location.hash.slice(2)).get('refresh_token');
    const maxAge = new URLSearchParams(window.location.hash.slice(4)).get('max_age');
    if (refreshToken !== null) {
      setCookie('refresh_token', refreshToken, {
          'path': '/',
          'secure': true,
          'HttpOnly': true,
          'Max-Age': maxAge
      });
    }
    const clientID = new URLSearchParams(window.location.hash.slice(3)).get('client_id');
    if (clientID !== null) {
      setCookie('client_id', clientID, {
          'path': '/',
          'secure': true,
          'HttpOnly': true,
          'Max-Age': maxAge
      });
    }

    window.history.replaceState({}, document.title, window.location.pathname);

    UsersService.get().then(resp => {
      user.setUser(resp.data.user)
      user.setIsAuth(true)
    }).catch(_ => {
      // console.log(e)
    }).finally(() => {
      setLoading(false)
    })
  })

  if (loading) {
    return <Loader />
  }

  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
};

export default observer(App);
