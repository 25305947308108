import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import classes from './Categories.module.scss'
import CategoriesService from '../../http/CategoriesService';
import { EXPENSE_CATEGORY_TYPE, INCOME_CATEGORY_TYPE } from '../../utils/consts';

import AppPageLayout from '../../components/PageLayout/AppPageLayout';
import ModalCategory from '../../components/PageLayout/Modal/ModalCategory';
import FilledPurpleSmallButton from '../../components/Common/Button/FilledPurpleSmallButton';
import Header from '../../components/PageLayout/Header/Header';

const Categories = () => {
    const [categoryID, setCategoryID] = useState()
    const [incomeCategories, setIncomeCategories] = useState()
    const [expenseCategories, setExpenseCategories] = useState()
    const [isModalCategoryOpen, setIsModalCategoryOpen] = useState(false);
    const [isModalCategoryEditOpen, setIsModalCategoryEditOpen] = useState(false);

    useEffect(() => {
        CategoriesService.getAll(INCOME_CATEGORY_TYPE).then((resp) => {
            setIncomeCategories(resp.data.categories);
        });
        CategoriesService.getAll(EXPENSE_CATEGORY_TYPE).then((resp) => {
            setExpenseCategories(resp.data.categories);
        });
    }, [isModalCategoryOpen, isModalCategoryEditOpen]);

    const handleCategoryItemOnClick = categoryID => () => {
        setCategoryID(categoryID)
        setIsModalCategoryEditOpen(true)
    }

    return (
        <AppPageLayout
            header={<Header
                title='CATEGORIES'
                button={<FilledPurpleSmallButton onClick={() => setIsModalCategoryOpen(true)}>Add category</FilledPurpleSmallButton>}
            />}
        >

            {isModalCategoryOpen && <ModalCategory setIsOpen={setIsModalCategoryOpen} />}
            {isModalCategoryEditOpen && <ModalCategory setIsOpen={setIsModalCategoryEditOpen} edit={true} categoryID={categoryID} />}

            <div className={classes.content}>
                <div className={classes.list}>
                    <div className={classes.title}>Income categories</div>
                    {
                        incomeCategories?.map((category) => {
                            return (
                                <div className={classes.item} key={category.id} onClick={handleCategoryItemOnClick(category.id)}>
                                    <div className={classes.name}>{category.name}</div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className={classes.list}>
                    <div className={classes.title}>Expense categories</div>
                    {
                        expenseCategories?.map((category) => {
                            return (
                                <div className={classes.item} key={category.id} onClick={handleCategoryItemOnClick(category.id)}>
                                    <div className={classes.name}>{category.name}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </AppPageLayout >
    );
};

export default observer(Categories);