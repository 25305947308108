import React from 'react';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const DateRange = (props) => {
    const styles = {
        height: "60px",
        width: "336px",
        padding: "0px 10px",
        border: "1px solid #ebebeb",
        backgroundColor: "transparent",
        borderRadius: "10px",
    }

    return (
        <RangePicker {...props} style={styles} />
    );
};

export default DateRange;